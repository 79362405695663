var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    page-container\n    w-screen\n    h-vh-9/10\n    relative\n    flex flex-wrap\n    justify-center\n  "},[_c('div',{staticClass:"container flex flex-1 justify-center flex-wrap",class:{ 'opacity-0': !_vm.showItems }},[_c('div',{staticClass:"w-full my-5 text-2xl text-center text-primary uppercase"},[_vm._v("\n      Preguntas frecuentes\n    ")]),_vm._v(" "),_c('div',{ref:"scroll",staticClass:"\n        Accordion\n        flex-auto\n        w-screen\n        justify-center\n        flex-shrink-0\n        max-h-8/10-screen\n        sm:max-h-8/10-screen\n        overflow-scroll\n        px-5\n        sm:px-10\n        pb-32\n        shadow\n        z-0\n      "},[_c('expander',{staticClass:"z-0 opacity-0 overflow-hidden",staticStyle:{"height":"0px !important"}}),_vm._v(" "),(_vm.isMobileView)?_vm._l((_vm.questionList),function(question,index){return _c('expander',{key:'q-mobile' + index,attrs:{"title":question.title,"height":_vm.getScaleImgValues(_vm.maxWidth, question.width_xs, question.height_xs)
              .h}},[_c('img',{staticClass:"w-full",attrs:{"src":question.imagen_xs,"width":_vm.getScaleImgValues(
                _vm.maxWidth,
                question.width_xs,
                question.height_xs
              ).w,"height":_vm.getScaleImgValues(
                _vm.maxWidth,
                question.width_xs,
                question.height_xs
              ).h}})])}):_vm._l((_vm.questionList),function(question,index){return _c('expander',{key:'q-' + index,attrs:{"title":question.title,"height":_vm.getScaleImgValues(_vm.maxWidth, question.width_xl, question.height_xl)
              .h}},[_c('img',{staticClass:"w-full",attrs:{"src":question.imagen_xl,"width":_vm.getScaleImgValues(
                _vm.maxWidth,
                question.width_xl,
                question.height_xl
              ).w,"height":_vm.getScaleImgValues(
                _vm.maxWidth,
                question.width_xl,
                question.height_xl
              ).h}})])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }